nav {
    min-height: 50px;
    max-height: 100px;
    background-color: #ff7c00;
    display: none;
}

#btn-menu-mobile {
    background-color: #ff7c00;
    border-color: #ff7c00;
    margin-left: 20px;
}

.offcanvas-body, .offcanvas-title{
    color: #113454;
}

.menu-mobile, .menu-desktop {
    list-style-type: none;
}

.menu-mobile li{
    padding: 20px 5px;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
}

.menu-mobile li:hover{
    background-color: lavender;
}

.menu-desktop { 
    padding-top: 0px;
    display: flex;
    padding-bottom: 5px;
    justify-content: center;
}

.menu-desktop li {
    display: inline;
    padding-top: 20px 0px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    padding: 10px;
    cursor: pointer;
}

.menu-desktop li:hover, #dropdown-basic:hover {
    color: #113454;
}

#dropdown-basic {
    background: none;
    border: none;
    padding: 0px !important;
    margin: 0px !important;
}

#dropdown svg {
    vertical-align: baseline !important;
  }

#dropdown-basic, .menu-desktop li{ 
    font-size: 12px;
}   

.menu-drop{
    background-color: #113454;   
    
}

.item-menu-drop{
    color: #fff;
    border-bottom: 1px solid #fff;
}

.menu-drop a:last-child{
    border-bottom: 0px;
}

@media only screen and (min-width: 576px)  {
    
}

@media only screen and (min-width: 768px)  {

    header div {
        justify-content: space-between;
    }

    #header-col-3 {
        text-align: right !important;
    }

    #btn-menu-mobile {
        display: none;
    }

    nav {
        display: block;
    }
}

@media only screen and (min-width: 887px)  {
    #dropdown-basic, .menu-desktop li{ 
        font-size: 13px;
    } 
}

@media only screen and (min-width: 992px)  {
    #dropdown-basic, .menu-desktop li{ 
        font-size: 15px;
    } 

    .menu-desktop { 
        padding-bottom: 6px;
    }
}

@media only screen and (min-width: 1200px)  {
    #dropdown-basic, .menu-desktop li{ 
        font-size: 17px;
    } 

    .menu-desktop { 
        padding-bottom: 7px;
    }
}