@import "react-alice-carousel/lib/alice-carousel.css";

.produto{
    margin-left: 10px;
    
}

.product-img{
    width: 100%;

}

.contem-carousel{
    background-color: black;
    padding: 20px 0px 20px 0px;
}

.two-car{
    background-color: #ff7c00;
    padding: 20px 0px 20px 0px;
}

.two-car .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item,
.two-car .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item--active{
    width: 200px !important;
}

.carousel-products{
    width: 90%;
    margin: auto;
}

.videos-car {
    width: 50% !important;
}

.imagens-car {
    width: 50% !important;
}

.card-body{
    background-color: #ff7c00;
    color: #fff;
    font-family: "Arial";
    padding: 5px !important;
}

.card-title .h5 {
    max-width: "37ch";
    text-overflow: "ellipsis";
    overflow: "hidden";
    
}

.text-elips {
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
    font-size: 18px;
    word-break: break-all;
}

.text-elips-expand {
    display: block;
    text-align: center;
    width: 100%;
    margin-top: -15px;
    position: absolute;
    margin-left: -10px;
    z-index: 1;
    font-size: 14px;
    cursor: pointer;
}

.produto .card-body {
    height: 70px;
}

.produto img {
    cursor: pointer;
}

.produto video {
    width: 100% !important;
}


@media only screen and (min-width: 576px)  {
    .videos-car {
        width: 40% !important;
    }
    
    .imagens-car {
        width: 60% !important;
    }

    .two-car .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item,
    .two-car .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item--active{
        width: 230px !important;
    }


}

@media only screen and (min-width: 768px)  {
    .videos-car {
        width: 40% !important;
    }
    
    .imagens-car {
        width: 60% !important;
    }

    .two-car .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item,
    .two-car .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item--active{
        width: 300px !important;
    }
}


@media only screen and (min-width: 992px)  {
    .videos-car {
        width: 40% !important;
    }
    
    .imagens-car {
        width: 60% !important;
    }

    .two-car .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item,
    .two-car .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item--active{
        width: 390px !important;
    }
}

@media only screen and (min-width: 1200px)  {
    .videos-car {
        width: 30% !important;
    }
    
    .imagens-car {
        width: 70% !important;
    }

    .two-car .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item,
    .two-car .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item--active{
        width: 390px !important;
    }
}

