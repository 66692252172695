

html, body, #root {
    margin: 0 !important;
    padding: 0 !important;
}


.contain-all {
  width: 100%;
  margin: 0 !important;
  

}

.row {
    width: 90%;
}

.row:first-child {
    margin-top: 20px;
}

div.scrollmenu {
    background-color: --color1;
    overflow: auto;
    white-space: nowrap;
    background-color: #113454;
    min-height: 100px;
}

div.scrollmenu .contem-itens{
    width: 90%;
    margin-bottom: 20px !important;
}

div.scrollmenu .item {
    background-color: #fff;
    display: inline-block;
}

div.scrollmenu::-webkit-scrollbar { 
    display: none;
}

.active {
    --bs-pagination-active-bg: #ff7c00 !important;
    --bs-pagination-active-border-color: #ff7c00 !important;
}

.contem-card-produto {
    text-align: center !important;
}

.card-produto {
    display: inline-block !important;
    margin: 10px;
}

.btn-wp {
    font-size: 50px;
    /* margin-left: 88%; */
}

@media only screen and (min-width: 768px)  {

    .btn-wp {
        font-size: 50px;
        /* margin-left: 92%;
        margin-top: 89vh; */
    }
}


@media only screen and (min-width: 992px)  {
    
}

@media only screen and (min-width: 1200px)  {
    .btn-wp {
        font-size: 60px;
        /* margin-left: 94%;
        margin-top: 88vh; */
    }
    
}

/* @media only screen and (min-height: 200px)  {
    .btn-wp {
        margin-top: 58vh;
    }
}

@media only screen and (min-height: 300px)  {
    .btn-wp {
        margin-top: 70vh;
    }
}

@media only screen and (min-height: 400px)  {
    .btn-wp {
        margin-top: 75vh;
    }
}

@media only screen and (min-height: 500px)  {
    .btn-wp {
        margin-top: 80vh;
    }
}

@media only screen and (min-height: 600px)  {
    .btn-wp {
        margin-top: 85vh;
    }
}

@media only screen and (min-height: 700px)  {
    .btn-wp {
        margin-top: 87vh;
    }
} */