#header-col-1, #header-col-2, #header-col-3 {
    margin: 10px;
}

#banner {
    width: 100%;
    min-height: 20px;
    padding: 0px;
    background-color: #e6e6e6;
    margin-top: -16px;
}

#banner div{
    border-radius: 0px;
}



.carousel {
    width: 100%;
    margin: auto;
}

#inner-banner{
    display: block;
    margin: auto;
    width: 90%;
    text-align: center;
    margin-top: 20px;
}

#inner-banner h3{
    font-size: 20px;
}

#inner-banner p{
    font-size: 18px;
    text-align: justify;
}

#inner-banner a .btn{
    left: '0' !important;  
    background-color: "#ff7c00  !important";
    color: "white  !important";
}

@media only screen and (min-width: 576px)  {
    
}

@media only screen and (min-width: 768px)  {
    #banner div{
        border-radius: 40px !important;
    }
    #banner {
        padding: 20px 0px !important;
    }

    .carousel {
        width: 90%;
    }

    #header-col-1, #header-col-2, #header-col-3 {
        margin: 0px;
    }
    
}


@media only screen and (min-width: 992px)  {
    
}

@media only screen and (min-width: 1200px)  {
    
}