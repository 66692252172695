.contem-info{
    width: 100%;
    min-height: 100px;
    padding: 20px 0px 20px 0px;
    background: #e6e6e6 !important;
    margin-top: -20px !important;
}

.info{
    width: 90%;
    margin: auto;
    text-align: center;
    justify-content: space-around;
}


.card-info {
    width: 250px !important;
    margin: 20px;
}

.card-info-header{
    min-height: 80px;
}

.card-info-contem-icon {
    margin: auto;
    background-color: #ff6600;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
}

.card-info-contem-icon svg{
    color: #fff;
    font-size: 32px;
    margin-top: 9px;
}

.card-info-text{
    font-family: "Arial";
    font-size: 20px;
}

.card-info-text {
    
}

@media only screen and (min-width: 576px)  {
    
}

@media only screen and (min-width: 768px)  {
    .card-info{
        margin: 0px;
    }
}


@media only screen and (min-width: 992px)  {
    
}

@media only screen and (min-width: 1200px)  {
    
}